import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import logo from "@/assets/homepage-logo.png";
import crimeCasesCover from "@/assets/crime-cases-cover.png";
import { AppRoutes } from "@/constants/app.routes";

import {
  CrimeCasesImage,
  ContentWrapper,
  LogoImage,
  EpisodeNumber,
  EpisodeName,
  ImpressumCustomized,
  CoverImageWrapper,
} from "./styles";

export const Home = () => {
  const user = useAppSelector(selectUserState).user;

  return (
    <ContentWrapper>
      <EpisodeNumber component="h3">002</EpisodeNumber>

      <LogoImage src={logo} alt="Crime Cases" />

      <EpisodeName component="h1">
        <span className="first-line">Tatort</span>
        <br />
        Tonstudio
      </EpisodeName>

      <Box
        sx={(theme) => ({
          margin: theme.spacing(4.6, 0),
          textAlign: "center",
        })}
      >
        <StyledButton
          component={Link}
          to={
            user === null
              ? AppRoutes.select_difficulty
              : AppRoutes.introduction_audio
          }
          variant="contained"
        >
          Spiel Starten
        </StyledButton>
      </Box>

      <CoverImageWrapper>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        >
          <ImpressumCustomized />
          <CrimeCasesImage src={crimeCasesCover} alt="Crime Cases Cover" />
        </Box>
      </CoverImageWrapper>
    </ContentWrapper>
  );
};
