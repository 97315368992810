import { createTheme, PaletteColor, PaletteColorOptions } from "@mui/material";

// Fonts
import "@fontsource/anton";

export const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#BB86FC",
    },
    secondary: {
      main: "#03DAC5",
    },
    mobile_wrapper: {
      main: "#2a2a2a",
    },
    grey: {
      card: "#383838",
    },
    brand: {
      green_1: "#1DE555",
      red_1: "#E51D1D",
    },
    mode: "dark",
  },
  spacing: (factor: number | string) =>
    typeof factor === "number" ? `${factor}rem` : factor,
  breakpoints: {
    values: {
      xs: 0,
      mobile_wrapper: 460,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // set to "false" to remove the breakpoint
    mobile_wrapper: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    mobile_wrapper: PaletteColor;
    brand: PaletteColor & {
      green_1?: string;
      red_1?: string;
    };
  }

  interface PaletteOptions {
    mobile_wrapper?: PaletteColorOptions;
    brand?: PaletteColorOptions & {
      green_1?: string;
      red_1?: string;
    };
  }
}

declare module "@mui/material" {
  interface Color {
    card?: string;
  }
}
