import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { useGetUserLogs } from "@/firebase/logs/use.get.user.logs";
import { usePersons } from "@/firebase/persons/use.persons";
import { selectUserState } from "@/store/slices/user.slice";
import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import { AppModal, AppModalProps } from "@/components/modals/app.modal";
import { AppRoutes } from "@/constants/app.routes";

import { handleCorrectAnswer } from "../shared/handle.correct.answer";
import { handleIncorrectAnswer } from "../shared/handle.incorrect.answer";
import PersonCard from "./person.card";
import { ContentWrapper, PersonsContainer, QuestionText } from "./styles";

export const SelectPersonView: React.FC = () => {
  const { persons } = usePersons();
  const [userAnswer, setUserAnswer] = useState("");
  const [modal, setModal] = useState<AppModalProps>({
    open: false,
    title: "",
    titleColor: "green",
    body: "",
    buttonText: "",
    onClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const user = useAppSelector(selectUserState).user!;
  const { userLogs } = useGetUserLogs(user.phone_number);
  const currentStage = useAppSelector(selectCurrentStage)!;
  const navigate = useNavigate();

  const handleOnSelect = (selected: string) => {
    setUserAnswer(selected);
  };

  const onShowSolution = (answer: string) => {
    const selectedPerson = persons.find(
      (p) =>
        p.name.toLocaleLowerCase().trim() === answer.toLocaleLowerCase().trim()
    );
    setUserAnswer(selectedPerson?.id ?? "");
  };

  const handleSubmitAnswer = () => {
    const _userAnswer = persons.find((p) => p.id === userAnswer)?.name;
    if (!_userAnswer) return;

    if (
      currentStage.answer_type === "select_person" && // For correct TS typings
      currentStage.answer.toLocaleLowerCase() ===
        _userAnswer.toLocaleLowerCase()
    ) {
      // Handle correct answer
      handleCorrectAnswer({
        solvedStage: currentStage,
        userAnswer: _userAnswer,
        navigate,
        setModal,
      });
    } else {
      // Handle incorrect answer
      handleIncorrectAnswer({
        currentStage,
        userAnswer: _userAnswer,
        userLogs,
        setModal,
        onShowSolution,
      });
    }
  };

  return (
    <ContentWrapper>
      <QuestionText>
        {currentStage.stage_number}. {currentStage.question}
      </QuestionText>

      <PersonsContainer>
        {/* Map over persons */}
        {persons.map((person) => {
          return (
            <PersonCard
              key={person.id}
              person={person}
              selected={userAnswer}
              onSelect={handleOnSelect}
            />
          );
        })}
      </PersonsContainer>
      <Box sx={{ mt: 6.4, textAlign: "center" }}>
        <StyledButton
          variant="contained"
          disabled={userAnswer === ""}
          onClick={handleSubmitAnswer}
        >
          ANTWORT ÜBERPRÜFEN
        </StyledButton>
      </Box>

      <Box mt={2} textAlign="center">
        <StyledButton to={AppRoutes.select_stage} component={Link}>
          Abbrechen
        </StyledButton>
      </Box>

      {/* Modal */}
      <AppModal {...modal} />
    </ContentWrapper>
  );
};
