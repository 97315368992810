import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// TODO: should be changed for production
const firebaseConfig = {
  apiKey: "AIzaSyDgzgQvZM0xlL3i0sd-Yd06ong1P5ht9Rc",
  authDomain: "cc-02-de-startgame.firebaseapp.com",
  projectId: "cc-02-de-startgame",
  storageBucket: "cc-02-de-startgame.appspot.com",
  messagingSenderId: "834773355839",
  appId: "1:834773355839:web:439551b54a76563964578a",
  measurementId: "G-B707FDEWGY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
