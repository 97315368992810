import React from "react";
import { alpha, Box, styled, Typography } from "@mui/material";

import { StyleUtils } from "@/constants/style.utils";

export const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "36rem",
  minWidth: "30rem",
  backgroundColor: theme.palette.grey.card,
  boxShadow: theme.shadows[2],
  textAlign: "center",
  padding: theme.spacing(1.6, 3.2),
}));

export const ModalTitle: React.FC<{
  title: string;
  titleColor?: "green" | "red" | "primary";
}> = ({ title, titleColor }) => {
  return (
    <Typography
      variant="subtitle1"
      fontSize={StyleUtils.fonts.size(4)}
      sx={(theme) => ({
        color:
          titleColor === "green"
            ? theme.palette.brand.green_1
            : titleColor === "red"
            ? theme.palette.brand.red_1
            : theme.palette.primary.main,
      })}
    >
      {title}
    </Typography>
  );
};

export const ModalBody: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <Typography
      variant="body2"
      fontSize={StyleUtils.fonts.size(3.5)}
      sx={(theme) => ({
        color: alpha(theme.palette.common.white, 0.6),
      })}
    >
      {text}
    </Typography>
  );
};
