import React, { ChangeEvent, MouseEvent, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  ThemeProvider,
} from "@mui/material";
import phone from "phone";
import { CgSpinner } from "react-icons/cg";

import { StyleUtils } from "@/constants/style.utils";
import { StyledButton } from "@/components/button";
import { PhoneNumber } from "@/models/shared";

import { countries, CountryCode, PhoneNumberState } from "./data";
import {
  CustomSelect,
  MenuItemBox,
  MenuItemImage,
  MenuItemText,
  PhoneNumberInputWrapper,
  theme,
} from "./styles";

interface PhoneNumberFormProps {
  onSubmit: (phoneNumber: PhoneNumber) => Promise<void>;
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumberState>({
    number: "",
    country_code: "+49",
    isValid: undefined,
  });

  const phoneNumberChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    if (/^\d+$/.test(value)) {
      const obj = phone(phoneNumber.country_code + value);

      setPhoneNumber((prev) => ({
        ...prev,
        number: value,
        isValid: obj.isValid,
      }));
      return;
    }

    setPhoneNumber((prev) => ({ ...prev, number: value, isValid: false }));
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    const button = e.currentTarget;
    button.disabled = true;
    setSubmitting(true);

    const obj = phone(phoneNumber.country_code + phoneNumber.number);
    const number = obj.phoneNumber!.slice(1);

    try {
      await onSubmit(number);
      button.disabled = false;
      setSubmitting(false);
    } catch (error) {
      process.env.NODE_ENV !== "production" && console.error(error);
      button.disabled = false;
      setSubmitting(false);
    }
  };

  return (
    <>
      <PhoneNumberInputWrapper>
        <ThemeProvider theme={theme}>
          <CustomSelect
            value={phoneNumber.country_code}
            variant="outlined"
            onChange={(e) => {
              setPhoneNumber((prev) => ({
                ...prev,
                country_code: e.target.value as CountryCode,
              }));
            }}
          >
            {countries.map((country) => {
              return (
                <MenuItem value={country.code} key={country.code}>
                  <MenuItemBox>
                    <MenuItemImage src={country.flag} alt="flag" />
                    <MenuItemText>{country.code}</MenuItemText>
                  </MenuItemBox>
                </MenuItem>
              );
            })}
          </CustomSelect>
        </ThemeProvider>

        <FormControl error variant="standard" fullWidth>
          <TextField
            label="Telefon"
            placeholder="152546593"
            variant="outlined"
            color={phoneNumber.isValid ? "success" : "primary"}
            error={phoneNumber.isValid === false}
            id="phone-number"
            InputLabelProps={{ shrink: true }}
            value={phoneNumber.number}
            onChange={phoneNumberChangeHandler}
            fullWidth
            InputProps={{
              sx: (theme) => ({
                ".MuiOutlinedInput-input": {
                  fontSize: StyleUtils.fonts.size(4),
                  padding: theme.spacing(1.3, 1.4),
                },
              }),
            }}
          />

          <FormHelperText id="phone-number">
            {phoneNumber.isValid === false && "Ungültige Nummer"}
          </FormHelperText>
        </FormControl>
      </PhoneNumberInputWrapper>
      <Box textAlign={"center"}>
        <StyledButton
          disabled={phoneNumber.isValid !== true}
          onClick={handleSubmit}
          variant="contained"
        >
          {submitting === false ? (
            "WEITER"
          ) : (
            <CgSpinner size={"16px"} className="spin" />
          )}
        </StyledButton>
      </Box>
    </>
  );
};

export default PhoneNumberForm;
