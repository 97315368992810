import { collection, doc, getDoc, increment, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { Log } from "@/models/log";
import {
  SelectedDifficulty,
  SelectedDifficultyLocalStorage,
  PhoneNumber,
} from "@/models/shared";
import { Stage } from "@/models/stage";
import { DBUser, User, UserWithPhoneNumber } from "@/models/user";
import { currentTimeInSeconds } from "@/utils/common";
import { Collections } from "@/constants/firebase.collections";

import { db, functions } from ".";
import { setLogDoc } from "./logs";

// Users Collection Ref
const usersRef = collection(db, Collections.USERS);

export const updateUserDoc = async (id: PhoneNumber, data: Partial<User>) => {
  const userDoc = doc(usersRef, id);
  await setDoc(userDoc, data, { merge: true });
};

export const getUserDoc = async (id: PhoneNumber) => {
  const userDocRef = doc(usersRef, id);
  const snapshot = await getDoc(userDocRef);
  if (snapshot.exists()) {
    return {
      phone_number: snapshot.id,
      ...snapshot.data(),
    } as UserWithPhoneNumber;
  } else {
    throw new Error("User not found");
  }
};

export const getOrSetUserWithPhoneNumber = async (
  phone_number: PhoneNumber,
  selected_difficulty?: SelectedDifficultyLocalStorage
): Promise<DBUser | null> => {
  const userDocSnapshot = await getDoc(doc(usersRef, phone_number));

  if (userDocSnapshot.exists()) {
    return {
      ...userDocSnapshot.data(),
      phone_number: userDocSnapshot.id,
    } as DBUser;
  } else if (selected_difficulty != null) {
    // if the user doesn't exist, create a new user in firestore
    const createNewUser = httpsCallable<
      {
        phone_number: PhoneNumber;
        selected_difficulty: SelectedDifficulty;
      },
      DBUser
    >(functions, "createNewUser");

    const { data: createdUser } = await createNewUser({
      phone_number,
      selected_difficulty,
    });

    // Create a log for started_game
    const startedGameLog: Log = {
      type: "started_game",
      timestamp: currentTimeInSeconds(),
    };

    setLogDoc(phone_number, startedGameLog);

    // Call the cloud function
    fetch(
      `https://us-central1-cc-02-de-startgame.cloudfunctions.net/startGameTwilio?phone_number=${phone_number}`,
      { mode: "no-cors" }
    );

    return createdUser;
  }

  return null;
};

export const incrementHintsUsedInUserDoc = async (
  id: PhoneNumber,
  data: { stage_number: Stage["stage_number"] }
) => {
  const userDoc = doc(usersRef, id);

  await setDoc(
    userDoc,
    {
      hints_used: {
        [`stage_${data.stage_number}`]: increment(1),
      },
    },
    { merge: true }
  );
};
