import React from "react";
import { Box, Modal } from "@mui/material";

import { StyledButton } from "@/components/button";

import { ModalBody, ModalContent, ModalTitle } from "./shared";

export interface AppModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string;
  buttonText: string;
  titleColor?: "green" | "red" | "primary";
}

// This is a generic modal
export const AppModal: React.FC<AppModalProps> = ({
  open,
  onClose,
  title,
  body,
  buttonText,
  titleColor = "green",
}) => {
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalContent>
          <Box mb={2}>
            <ModalTitle title={title} titleColor={titleColor} />
          </Box>

          <Box mb={2}>
            <ModalBody text={body} />
          </Box>

          <Box mb={2}>
            <StyledButton onClick={onClose}>{buttonText}</StyledButton>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};
